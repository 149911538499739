<template>
  <v-container>

    <v-form v-on:submit.prevent="save" ref="form" class="pa-6">

      <v-row justify="center" dark color="primary">
        <!-- <v-toolbar min-width="100%" dark color="primary"> -->

        <v-col cols="12" md="8" lg="6">
          <!-- <v-alert type="info" class="d-flex align-items-center" v-model="showAlert" show >
              Data Saved
            </v-alert> -->
          <v-alert type="success" v-if="showAlert">
            Data Saved
          </v-alert>
          <!-- <v-alert type="warning" v-if="showoffAlert">
        Please enter all inputs...
      </v-alert> -->
          <!-- <v-alert
              class="d-flex align-items-center"
        variant="danger"
        dismissible
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert=false"
      >
        Dismissible Alert!
      </v-alert> -->


          <v-text-field v-model="title" :rules="$requiredRules" label="Title*"></v-text-field>


          <v-textarea rows="5" :rules="$requiredRules" v-model="content" label="Content*"></v-textarea>

          <v-btn color="secondary" type="submit" class="orange mx-5 float-right" @click="save_con">Save </v-btn>

          <!-- <v-btn color="secondary" type="submit"  @click="save">Save and Contineue </v-btn>  -->
          <index-view :message="message
          "></index-view>
        </v-col>

        <!-- </v-toolbar> -->

      </v-row>

    </v-form>
  </v-container>
</template>

<script>
import indexVue from './index.vue';


export default {
  // import jwtmaker from "../../services/jwtmaker";
  components: { indexVue },
  // props: ["class_id"],
  data: function () {


    return {

      message: "Data has been saved",
      form: "",

      title: '',
      content: '',
      showAlert: false,
      showoffAlert: false,



    };
  },

  methods: {

    save() {
      if (this.$refs.form.validate()) {
        const data = {

          title: this.title,
          content: this.content,

          showAlert: this.showAlert = true,
          // showAlert:this.showAlert=false,
          // title: this.title,
          // content: this.content,




        };


        this.$api.post('/notice/', data)
          .catch((err) => {
            console.log(err);

            this.showoffAlert = true
          });

        this.title = null;
        this.content = null;
        // this.save();
      }


    },
    save_con() {
      this.save();
      this.$router.push("/organization/notice/list/");
    }


  },


};

</script>



</style>